/**
* not used! see styled components..
 */
.muEditorObsolete {
    box-sizing: border-box;
    border: 1px solid #ddd;
    cursor: text;
    padding: 16px;
    border-radius: 2px;
    margin-bottom: 2em;
    box-shadow: inset 0px 1px 8px -3px #ABABAB;
    background: #fefefe;
}

.muEditor :global(.public-DraftEditor-content) {
    min-height: 140px;
}

/**
* Custom styles used for rendering blocks in MuInternalEditor
 */
.muContainer {
    /*cursor: text;*/
}

.muContainer div:first-of-type {
    margin-top: 0;
}

.muBlock {
    /*background-color: lightcyan;*/
}

/* select all muParagraph children of editor, set ZERO margin top */
.public-DraftEditor-content .muParagraph {
  /*margin: .875em 0;*/
  margin: 0 0;
  overflow-wrap: break-word;
  word-break: break-word; /* Chrome, Safari */
  word-wrap: break-word; /* IE11, Firefox */
}

/* then UNDO HACK everyone except first one */
.public-DraftEditor-content .muParagraph ~ .muParagraph {
  margin: .875em 0;
}

.muQuote {
    /*background-color: lightpink;*/
}

.muCode {
    /*background-color: lightcoral;*/
}

.muBlank {
  /* for inline editing */
}
