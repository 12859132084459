.draftJsLinkifyPlugin__link__2ittM, .draftJsLinkifyPlugin__link__2ittM:visited {
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  font-size: 1em;
  color: #FF5C39;

  border-bottom: solid 1px transparent;
  transition: border-bottom 0.2s ease-out;
  cursor: pointer;
}

.draftJsLinkifyPlugin__link__2ittM:hover, .draftJsLinkifyPlugin__link__2ittM:focus {
  color: #FF5C39;
  outline: 0; /* reset for :focus */
  cursor: pointer;
  border-bottom: solid 1px #FF5C39;
}

/*.draftJsLinkifyPlugin__link__2ittM:active {*/
  /*color: #FF5C39;*/
/*}*/
