.react-resizable {
  position: relative;
}
.react-resizable-handle {
  display: block;
  /*background-color: pink;*/

  position: absolute;
  width: 20px;
  height: calc(100vh - 3.125em - 3em - 1em);


  top: .5em;
  right: -10px;
  /*bottom: .5em;*/


  /*background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');*/
  /*background-position: bottom right;*/
  padding: 0 3px 3px 0;
  /*background-repeat: no-repeat;*/
  background-origin: content-box;
  box-sizing: border-box;
  cursor: col-resize;

  /*opacity: .5;*/


}

.react-resizable-handle:before {
  content: '';
  position: absolute;
  border-right: 2px dotted;
  border-color: transparent;
  width: 10px;
  height: 100%;

  transition: border-right-color .25s ease-in;
}

.react-resizable-handle:hover:before {
  border-color: #00778B;
}

.react-resizable-handle:after {
  content: url('./resources/slider.png');
  position: absolute;
  opacity: 0;

  left: -9px;
  top: -9px;

  transition: opacity .25s ease-in;
}

.react-resizable-handle:hover:after {
  opacity: 1;
}

/* TODO: add :hover:after content image position avsolute like in design? */
