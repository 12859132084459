a.markedLink {
    text-decoration: none;
    font-family: 'Lato', sans-serif;
    font-size: 1em;
    line-height: 1.5em;
    color: #FF5C39;
    border-bottom: solid 1px transparent;
    transition: border-bottom 0.2s ease-out;
}

a.markedLink:hover {
    border-bottom: solid 1px #FF5C39;
}

h1.markedHeader {
    color: #333333;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    line-height: 2.75em;
    font-size: 2em;
    margin: 0;
}
h2.markedHeader {
    color: #333333;
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    line-height: 2em;
    font-size: 1.375em;
    margin: 0;

}
h3.markedHeader {
    color: #333333;
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    line-height: 1.75em;
    font-size: 1.25em;
    margin: 0;

}

h4.markedHeader {
    color: #333333;
    font-family: 'Lato', sans-serif;
    font-weight: normal;
    line-height: 1.5em;
    font-size: 1.15em;
    margin: 0;
}

/* TODO: margin on paragraphs in rendered markdown? should also add margin on block in draftjs? */

/*.markedParagraph {*/
    /*!*margin-top: 0;*!*/
    /*!*margin-bottom: 0;*!*/
/*}*/

.markedContainer p:first-of-type {
    margin-top: 0;
}
